/**
 *
 *
 * OFF NAVIGATION MENU
 *
 *
 */
const nav = document.getElementById("menu");
const btn = document.getElementById("menuBtn");
const header = document.querySelector(".site-header");

btn.addEventListener("click", function (e) {
  navToggle(e);
});

function navToggle(e) {
  btn.classList.toggle("open");
  nav.classList.toggle("open");
  header.classList.toggle("open");
}
