import "./components/toggle-nav";
//import "./components/accordeon";
// import "./components/block-tabs";

import Swiper, { Navigation } from "swiper";
import Headroom from "headroom.js";

// init Swiper:
// const swiper = new Swiper(".swiper", {
//   modules: [Navigation],
//   slidesPerView: "auto",
//   spaceBetween: 30,
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
// });
const swiperChars = new Swiper(".swiper-characters", {
  modules: [Navigation],
  slidesPerView: 1.1,
  spaceBetween: 30,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const swiperSettings = new Swiper(".swiper-settings", {
  modules: [Navigation],
  slidesPerView: "auto",
  spaceBetween: 30,
  navigation: {
    nextEl: ".swiper-button-next_settings",
    prevEl: ".swiper-button-prev_settings",
  },
});

const swiperCabaret = new Swiper(".swiper-cabaret", {
  modules: [Navigation],
  slidesPerView: "auto",
  spaceBetween: 30,
  navigation: {
    nextEl: ".swiper-button-next_cabaret",
    prevEl: ".swiper-button-prev_cabaret",
  },
});

// select your header or whatever element you wish
const header = document.querySelector(".site-header");

const headroom = new Headroom(header);
headroom.init();
